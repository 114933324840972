<template>
  <div class="fixed inset-0 flex items-center justify-center">
    <img class="absolute inset-0 object-cover object-center w-full h-full blur-sm" src="/assets/background.webp" alt="Background" />
      <main>
        <slot />
      </main>
  </div>
</template>

<style>
img, button, input:focus-visible {
    outline: none;
}
</style>
